* Created by hansxing on 2018/9/3.
*/
<style lang="scss" scoped>
.product-list {
    .product-list-inline {
        .el-form-item {
            width: 135px;
        }
    }

    .product-check-list {
        margin-top: 5px;

        .check-list-title {
            font-size: 14px;
            margin-right: 20px;
        }

        .check-list-group {
            display: inline-block;
        }

    }

    .product-list-table {
        background: #fff;
        margin-top: 10px;
        /*padding: 0 20px;*/
    }

    .search-show-product {
        display: inline;
    }

    &::v-deep {
        .list-search-before {
            width: calc(100% - 230px);
        }

        .list-search-after {
            width: 230px;
        }
    }

}

.product-list /deep/ {
    .table-info {
        .el-table__fixed-right {
            right: 10px !important;
        }

        .el-table__fixed-right-patch {
            width: 10px !important;
        }
    }
}


</style>
<style lang="scss">
.upper-lower-lines-dialog {
    top: 50%;
    margin: auto;

    .el-dialog__header {
        display: none;
    }

    .el-dialog__body {
        text-align: center;
        font-size: 16px;
    }

    .el-dialog__footer {
        padding: 0 20px 20px 20px;
        text-align: center;
    }
}

.product-list-table {
    .operation-btn {
        .el-button {
            padding: 0;
            margin: 5px;
            margin-left: 0;
            float: left;
        }
    }
}


</style>
<template>
    <div>
        <el-main class="com-eTrip-section">
            <div class="eTrip-section-cont product-list">
                <com-list-search>
                    <div slot="list-search-before">
                        <el-form :inline="true" :model="productSearchF" size="small">
                            <el-form-item label="" v-if="userInfo.userType == 0">
                                <el-autocomplete
                                    class="inline-input"
                                    v-model="productSearchF.operator"
                                    :fetch-suggestions="querySearchOperator"
                                    placeholder="运营商"
                                    @select="operatorId"
                                ></el-autocomplete>
                            </el-form-item>
                            <el-form-item label="" v-if="userInfo.userType == 0 && userInfo.userType == 2">
                                <el-autocomplete
                                    class="inline-input"
                                    v-model="productSearchF.resourceSupplier"
                                    :fetch-suggestions="querySearchSupplier"
                                    placeholder="资源供应商"
                                    @select="resourceSupplierId"
                                ></el-autocomplete>
                            </el-form-item>
                            <el-form-item label="">
                                <el-input v-model="productSearchF.productId" placeholder="产品ID"></el-input>
                            </el-form-item>
                            <el-form-item label="">
                                <el-input v-model="productSearchF.productName" placeholder="产品名称"></el-input>
                            </el-form-item>
                            <el-form-item label="">
                                <el-input v-model="productSearchF.resourcesId" placeholder="资源ID"></el-input>
                            </el-form-item>
                            <el-form-item label="">
                                <el-input v-model="productSearchF.resourcesName" placeholder="资源名称"></el-input>
                            </el-form-item>
                            <el-form-item label="">
                                <el-select v-model="productSearchF.productTypeMod" placeholder="请选择产品类型">
                                    <el-option label="全部类型" value=""></el-option>
                                    <el-option
                                        v-for="(item,ixType) in productTypeOp"
                                        :key="ixType"
                                        :label="item.typeOpLabel"
                                        :value="item.typeOpValue">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="">
                                <el-select v-model="productSearchF.productShapeMod" placeholder="请选择产品形态">
                                    <el-option label="全部形态" value=""></el-option>
                                    <el-option
                                        v-for="item in productForms"
                                        :key="item.value"
                                        :label="item.name"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <div class="search-show-product" v-if="productBasicSenSw == true">
                                <el-form-item label="">
                                    <el-autocomplete
                                        class="inline-input"
                                        v-model="productSearchF.originCity"
                                        :fetch-suggestions="querySearchCity"
                                        placeholder="出发地"
                                        @select="originCityId"
                                    ></el-autocomplete>
                                </el-form-item>
                                <el-form-item label="">
                                    <el-autocomplete
                                        class="inline-input"
                                        v-model="productSearchF.destinationCity"
                                        :fetch-suggestions="querySearchCity"
                                        placeholder="目的地"
                                        @select="destinationCityId"
                                    ></el-autocomplete>
                                </el-form-item>
                                <el-form-item label="">
                                    <el-input v-model="productSearchF.orderContactName"
                                              placeholder="订单联系人姓名"></el-input>
                                </el-form-item>
                                <el-form-item label="">
                                    <el-input v-model="productSearchF.orderContactNum"
                                              placeholder="订单联系人手机"></el-input>
                                </el-form-item>
                                <el-form-item label="">
                                    <el-input v-model="productSearchF.productContName"
                                              placeholder="产品联系人姓名"></el-input>
                                </el-form-item>
                                <el-form-item label="">
                                    <el-input v-model="productSearchF.productContNum"
                                              placeholder="产品联系人手机"></el-input>
                                </el-form-item>
                                <el-form-item label="" class="form-item-product">
                                    <el-select v-model="productSearchF.onlineCheckLabel" placeholder="请选择上线渠道">
                                        <el-option label="上线渠道" value=""></el-option>
                                        <el-option
                                            v-for="(item,idxChannel) in onlineChannelsData"
                                            :key="idxChannel"
                                            :label="item.channelTitle"
                                            :value="item.channelLabel">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="" class="form-item-product">
                                    <el-select v-model="productSearchF.statusCheckLabel" placeholder="请选择上线状态">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option
                                            v-for="(item,idx) in statusChannels"
                                            :key="idx"
                                            :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                        </el-form>
                    </div>
                    <div slot="list-search-after" class="list-search-after_sec">
                        <el-button class="search-after-switch" @click="btnBasicSeniorS"
                                   v-if="productBasicSenSw == false">
                            高级筛选<i class="el-icon-caret-bottom"></i></el-button>
                        <el-button class="search-after-switch" @click="btnBasicSeniorS"
                                   v-if="productBasicSenSw == true">
                            基本筛选<i class="el-icon-caret-top"></i></el-button>
                        <el-button @click="productSResClick()">重置</el-button>
                        <el-button type="primary" @click="screenProClick()">搜索</el-button>
                    </div>
                </com-list-search>
                <div class="product-list-table el-table_border_none">
                    <el-table
                        class="table-info"
                        border
                        :data="productTable"
                        v-loading="productListLoading"
                        :height="tableH"
                        style="width: 100%">
                        <el-table-column
                            prop="productNo"
                            label="产品ID"
                            width="110">
                        </el-table-column>
                        <el-table-column
                            prop="productName"
                            min-width="120"
                            label="产品名称">
                        </el-table-column>
                        <el-table-column
                            label="类型/形态"
                            width="110">
                            <template slot-scope="scope">
                                <span>{{ formatProductForm(scope.row.productForm) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="出发地-目的地"
                            width="120">
                            <template slot-scope="scope">
                                <span v-text="scope.row.startCityName"></span><br/>
                                至<span v-text="scope.row.destCityName"></span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="订单联系人"
                            width="110">
                            <template slot-scope="scope">
                                <span v-text="scope.row.orderContractName"></span>
                                <br/>
                                <span v-text="scope.row.orderContractPhone"></span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="产品联系人"
                            width="110">
                            <template slot-scope="scope">
                                <span v-text="scope.row.productContractName"></span>
                                <br/>
                                <span v-text="scope.row.productContractPhone"></span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="operatorName"
                            label="运营商"
                            min-width="80">
                        </el-table-column>
                        <el-table-column
                            label="上线渠道"
                            width="300">
                            <template slot-scope="scope">
                                <p v-for="(vm,index) in onlineChannelDesc(scope.row.productOnlineChannel)"
                                   :key="index">{{ vm.channelTitle }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="操作"
                            width="100">
                            <template slot-scope="scope">
                                <div class="operation-btn clear">
                                    <el-button @click="goResourcesClick(scope.row)" type="text">资源</el-button>
                                    <el-button @click="goResourceManClick(scope.row)" v-if="scope.row.productType!=7"
                                               type="text">库存
                                    </el-button>
                                    <el-button @click="upperLowerClick(0,scope.row)" type="text"
                                               v-if="scope.row.productStatus == '0'">上线
                                    </el-button>
                                    <el-button @click="upperLowerClick(1,scope.row)" type="text"
                                               v-if="scope.row.productStatus == '1'">下线
                                    </el-button>
                                    <el-button type="text" @click="goEdit(scope.row)">编辑</el-button>
                                    <el-button type="text" @click="handleCopy(scope.row)">复制</el-button>
                                    <el-button v-if="scope.row.productStatus == '0'"
                                               type="text" @click="delProduct(scope.row)"
                                               style="color: #ff3b30">删除
                                    </el-button>
                                    <el-button :disabled="scope.row.productOnlineChannel.slice(0,1)!=='1'" type="text"
                                               @click="handleCopyLink(scope.row)">复制链接
                                    </el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>

                    <div class="table-page-number">
                        <div class="block">
                            <el-pagination
                                @current-change="productListPageChange"
                                :current-page.sync="productListPage.currentPage"
                                :page-size="productListPage.pageSize"
                                layout="total, prev, pager, next"
                                :total="productListPage.totalPage">
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </div>

            <el-dialog
                title=""
                :visible.sync="dialogUpperLower"
                custom-class="upper-lower-lines-dialog"
                width="260px"
                top="-71px"
                :close-on-click-modal="false"
                :show-close="false">
                <span>{{ dialogUpLowInfo.content }}</span>
                <div style="margin-top: 30px" v-if="dialogUpLowInfo.tableCont.productStatus == '0'">
                    <el-checkbox-group v-model="dialogUpLowCheck" style="text-align: left;padding-left: 20px">
                        <el-checkbox :label="vm.channelLabel"
                                     style="display: block;margin-left: 0"
                                     v-for="(vm,index) in onlineChannelsData" :key="index">
                            {{ vm.channelTitle }}
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
                <span slot="footer" class="dialog-footer" v-if="dialogUpLowInfo.tableCont.productStatus == '0'">
                    <el-button @click="proCancelClick()">取 消</el-button>
                    <el-button type="primary" @click="ProOnOffLineClick('productOnline');">确 定</el-button>
                </span>
                <span slot="footer" class="dialog-footer" v-if="dialogUpLowInfo.tableCont.productStatus == '1'">
                    <el-button @click="proCancelClick()">取 消</el-button>
                    <el-button type="primary" @click="ProOnOffLineClick('productOffline');">确 定</el-button>
                </span>
            </el-dialog>


        </el-main>
    </div>

</template>

<script type="text/ecmascript-6">
import {checkStringFun, copyToClipboard} from '@/common/js/Utils';
import service from '../resourceManage/service.js';
import comListSearch from '../../../components/comListSearch/index.vue'
import {productFormList} from '@/data/index'
import {getCityData} from "@/urls";
import config from '@/config/index'

export default {

    //定义模版数据
    name: 'productList',
    data() {
        return {
            tableH: 'calc(100vh - 307px)',
            productSearchF: {
                operator: '',  // 运营商
                operatorId: '',  // 运营商Id
                resourceSupplier: '',  // 资源供应商
                resourceSupplierId: '',  // 资源供应商id
                productId: '',  // 产品ID
                productName: '',  // 产品名称
                resourcesId: '',  // 资源ID
                resourcesName: '',  // 资源名称
                productTypeMod: '',  // 产品类型
                productShapeMod: '',  // 产品形态
                originCity: '',  // 出发地
                originCityId: '',  // 出发地ID
                destinationCity: '',  // 目的地
                destinationCityId: '',  // 目的地ID
                orderContactName: '',  // 订单联系人姓名
                orderContactNum: '',  // 订单联系人手机
                productContName: '',  // 产品联系人姓名
                productContNum: '',  // 产品联系人手机
                onlineCheckLabel: [],  // 上线渠道
                statusCheckLabel: '',  // 上线状态
            },
            productListLoading: false,
            productBasicSenSw: false,  // 基本-高级==筛选切换
            dialogUpperLower: false,  // 上下线弹窗
            dialogUpLowCheck: [],//  上下线选择
            productTypeOp: [{  // 产品类型
                typeOpValue: '1',
                typeOpLabel: '实时用车'
            }, {
                typeOpValue: '2',
                typeOpLabel: '旅游包车'
            }, {
                typeOpValue: '3',
                typeOpLabel: '旅游线路'
            },
                {
                    typeOpValue: '7',
                    typeOpLabel: '即时预约'
                }],

            productForms: productFormList,
            onlineChannels: [
                {  // 上线渠道
                    channelLabel: '1', channelTitle: "TDS端"  //0
                }, {channelLabel: '2', channelTitle: "游客端"},
                {channelLabel: '3', channelTitle: "[小程序]900出行同业端"},
                {channelLabel: '4', channelTitle: "[小程序]900出行游客端"},
                {channelLabel: '5', channelTitle: "[小程序]邮轮中心"},
                {channelLabel: '6', channelTitle: "[小程序]烟台分销"},
                {channelLabel: '7', channelTitle: "[小程序]即时预约"}
            ],
            statusChannels: [{  // 上线状态
                value: '0',
                label: "未上线"
            }, {
                value: '1',
                label: "已上线"
            }],  // 上线状态
            operatorList: [],  // 运营商列表
            supplierList: [],  // 供应商列表

            cityLists: [], // 服务城市列表

            productTable: [],
            dialogUpLowInfo: {
                content: '',
                tableCont: {},
            },
            productListPage: {  // 列表分页
                pageSize: 10,  // 每页显示条目个数
                totalPage: 1,  // 总条目个数
                currentPage: 1,  // 当前页数
            },
        }
    },
    components: {comListSearch},
    //计算属性
    computed: {
        // cityLists() {
        //     return this.$store.state.serverCity;
        // },
        userInfo() {
            return this.$store.state.loginUserInfo
        },
        onlineChannelsData() {
            return this.onlineChannels.filter(({channelLabel}) => channelLabel > 0)
        }
    },
    //主件被加载完成
    mounted: function () {
        this.productScreenClick();
        this.getServerCity();
    },
    //定义事件方法
    methods: {
        /**
         * 格式产品形态
         */
        formatProductForm(type) {
            const ret = this.productForms.find((item) => item.value == type)
            if (ret)
                return ret.name
            else
                return ''
        },

        async getServerCity() {
            const ret = await getCityData(this, '2');
            // console.log(ret, '================');
            // return ret;
            if (ret)
                this.cityLists = ret;
        },

        //设置渠道描述
        onlineChannelDesc(channel) {
            if (!channel)
                return '';
            const channels = channel.split('');
            const des = [];
            channels.forEach((item, i) => {
                if (item == 1)
                    des.push(this.onlineChannels[i])
            })

            if (des.length == 0)
                return [
                    {  // 上线渠道
                        channelLabel: '0', channelTitle: "未上架"  //0
                    }
                ]
            else
                return des;
        },
        createFilter(itemString) {
            return (restaurant) => {
                return (restaurant.value.indexOf(itemString) === 0);
            };
        },
        querySearchCity(cityListString, cb) {  // 城市
            let cityList = this.cityLists;
            cityList.forEach(item => {
                item.value = item.city;
            })
            let cityListRes = cityListString ? cityList.filter(this.createFilter(cityListString)) : cityList;
            // 调用 callback 返回建议列表的数据
            cb(cityListRes);
        },
        querySearchOperator(operatorString, cb) {  // 运营商列表
            let operatorLists = this.operatorList;
            operatorLists.forEach(item => {
                item.value = item.companyName;
            })
            let operatorRes = operatorString ? operatorLists.filter(this.createFilter(operatorString)) : operatorLists;
            // 调用 callback 返回建议列表的数据
            cb(operatorRes);
        },
        querySearchSupplier(supplierString, cb) {  // 供应商列表
            let supplierLists = this.supplierList;
            supplierLists.forEach(item => {
                item.value = item.supplierName;
            })
            let supplierRes = supplierString ? supplierLists.filter(this.createFilter(supplierString)) : supplierLists;
            // 调用 callback 返回建议列表的数据
            cb(supplierRes);
        },
        originCityId(item) {  // 出发
            this.productSearchF.originCityId = item.shortCode
        },
        destinationCityId(item) {  // 目的
            console.log(item, "==-----------");
            this.productSearchF.destinationCityId = item.shortCode
        },
        operatorId(item) {  // 运营商
            this.productSearchF.operatorId = item.refId
        },
        resourceSupplierId(item) {  // 资源供应商
            this.productSearchF.resourceSupplierId = item.id
        },
        btnBasicSeniorS() {  // 基本-高级==筛选切换
            this.productBasicSenSw = !this.productBasicSenSw;
        },
        productSResClick() {  // 重置
            this.productSearchF = {
                operator: '',  // 运营商
                resourceSupplier: '',  // 资源供应商
                productId: '',  // 产品ID
                productName: '',  // 产品名称
                resourcesId: '',  // 资源ID
                resourcesName: '',  // 资源名称
                productTypeMod: '',  // 产品类型
                productShapeMod: '',  // 产品形态
                originCity: '',  // 出发地
                destinationCity: '',  // 目的地
                orderContactName: '',  // 订单联系人姓名
                orderContactNum: '',  // 订单联系人手机
                productContName: '',  // 产品联系人姓名
                productContNum: '',  // 产品联系人手机
                onlineCheckLabel: [],  // 上线渠道
                statusCheckLabel: '',  // 上线状态
            };
            this.productScreenClick()

        },
        screenProClick() {
            this.productListPage.currentPage = 1;
            this.productScreenClick()
        },
        async productScreenClick() {  //
            this.productListLoading = true;
            let form = {
                pageIndex: this.productListPage.currentPage,
                pageSize: this.productListPage.pageSize
            };
            if (this.productSearchF.operatorId) form.productOperatorId = this.productSearchF.operatorId; // 运营商
            if (this.productSearchF.resourceSupplierId) form.resourceSupplierId = this.productSearchF.resourceSupplierId;  // 资源供应商
            if (this.productSearchF.productId) form.productNo = this.productSearchF.productId;  // 产品ID
            if (this.productSearchF.productName) form.productName = this.productSearchF.productName;  // 产品名称
            if (this.productSearchF.resourcesId) form.resourceNo = this.productSearchF.resourcesId;  // 资源ID
            if (this.productSearchF.resourcesName) form.resourceName = this.productSearchF.resourcesName;  // 资源名称
            if (this.productSearchF.productTypeMod) form.productType = this.productSearchF.productTypeMod;  // 产品类型
            if (this.productSearchF.productShapeMod) form.productForm = this.productSearchF.productShapeMod;  // 产品形态
            if (this.productSearchF.originCityId) form.startCityId = this.productSearchF.originCityId;  // 出发地
            if (this.productSearchF.destinationCityId) form.destCityId = this.productSearchF.destinationCityId;  // 目的地
            if (this.productSearchF.orderContactName) form.orderContractName = this.productSearchF.orderContactName;  // 订单联系人姓名
            if (this.productSearchF.orderContactNum) form.orderContractPhone = this.productSearchF.orderContactNum;  // 订单联系人手机
            if (this.productSearchF.productContName) form.productContractName = this.productSearchF.productContName;  // 产品联系人姓名
            if (this.productSearchF.productContNum) form.productContractPhone = this.productSearchF.productContNum;  // 产品联系人手机

            if (this.productSearchF.onlineCheckLabel) form.productOnlineChannel = checkStringFun(6, this.productSearchF.onlineCheckLabel);  // 上线渠道
            if (this.productSearchF.statusCheckLabel) form.productStatus = this.productSearchF.statusCheckLabel;// 上线状态

            try {
                let res = await this.http('/galaxyProduct/productPageList', form, 'POST');
                if (res.success && res.data) {
                    this.operatorList = res.data.loginuserAdminRspDtos;
                    this.productTable = res.data.pageList.data;
                    this.supplierList = res.data.supplierRspDtos;
                    this.productListPage.totalPage = res.data.pageList.total;

                }
                this.productListLoading = false;
//                    else {
                // this.messageError(res.errors[0].message);
//                        this.messageError('系统出错，请稍后!');
//                    }
            } catch (error) {
                this.messageError();
                this.productListLoading = false;
            }


        },
        goResourcesClick(productItem) {  // 资源跳转
            this.$router.push({
                name: 'editresourceManage',
                params: {
                    productId: productItem.productId,
                    productNo: productItem.productNo,
                    productOperatorId: productItem.productOperatorId,
                    productType: productItem.productType,
                    productForm: productItem.productForm
                }

            })
        },
        async goResourceManClick(productManItem) {// 资源管理
            const ret = await service.getResourcesList(this, productManItem.productId);
            if (ret.data && ret.data.length > 0) {
                this.sessionStorage.setObject('resources_list', ret.data);
                this.$router.push({
                    name: 'editpriceInventory',
                    params: {
                        productId: productManItem.productId,
                        productNo: productManItem.productNo,
                        resourceId: productManItem.resourceId || -1,
                        productOperatorId: productManItem.productOperatorId,
                        productType: productManItem.productType,
                        productForm: productManItem.productForm
                    }

                })
            } else {
                this.$message.error('请先添加资源')
            }

        },
        proCancelClick() {
            this.dialogUpperLower = false;
            this.dialogUpLowInfo.tableCont = {};
            this.dialogUpLowCheck = [];
        },
        upperLowerClick(type, tableInfo) {  // 上下线
            this.dialogUpperLower = !this.dialogUpperLower;
            this.dialogUpLowInfo.tableCont = tableInfo;

            switch (type) {
                case 0:
                    this.dialogUpLowInfo.content = '请选择产品上线渠道';
                    break;
                case 1:
                    this.dialogUpLowInfo.content = '确定下线产品吗？';
                    break;
            }
        },

        async ProOnOffLineClick(Interface) {  // 产品上线
            let form = {
                channel: checkStringFun(7, this.dialogUpLowCheck),  //上下线渠道:tds,游客端 ,
                id: this.dialogUpLowInfo.tableCont.productId,  //产品id
            }
            if (checkStringFun(7, this.dialogUpLowCheck) === '0000000' && Interface === 'productOnline') {
                this.$message({
                    showClose: true,
                    message: '您还未选择产品上线渠道',
                    type: 'error'
                });
            } else {
                try {
                    let res = await this.http('/galaxyProduct/' + Interface, form, 'PUT');
                    let putMessageSuccessTitle = '';
                    if (res.success) {
                        this.dialogUpperLower = false;
                        this.dialogUpLowInfo.tableCont = {};
                        this.dialogUpLowCheck = [];
                        // this.screenProClick();
                        this.productScreenClick();
                        if (Interface == 'productOnline') {
                            putMessageSuccessTitle = '产品上线成功';
                        } else if (Interface == 'productOffline') {
                            putMessageSuccessTitle = '产品下线成功';
                        }
                        this.$message({
                            message: putMessageSuccessTitle,
                            type: 'success'
                        });

                    }

                } catch (error) {
                    this.messageError()
                }
            }

        },

        productListPageChange(val) {
            this.productListPage.currentPage = val;
            this.productScreenClick();
        },
        goEdit(item) {
            this.$router.push({
                name: 'editbasicInfo',
                params: {
                    productId: item.productId,
                    tabIndex: 1
                }

            })
        },
        async delProduct(item) {
            this.$confirm('是否删除该产品', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let form = {
                    id: item.productId,
                    isDelete: 1
                }
                let res = await this.http('/galaxyProduct/deleteProduct', form, 'POST');
                if (res.success) {
                    this.productTable.forEach((p, index) => {
                        if (item.productId == p.productId) {
                            return this.productTable.splice(index, 1);
                        }
                    })
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                }
            }).catch(() => {
            });
        },
        handleCopyLink({productId}) {
            copyToClipboard(`${config.B2B_url}/line/${productId}`, (bool) => {
                bool ? this.$message.success('产品链接已复制到剪贴板') : this.$message.error('产品链接已复制失败')
            });
        },
        async handleCopy(item) {
            this.$confirm('是否复制该产品', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let form = {
                    id: item.productId,}
                let res = await this.http('/galaxyProduct/copyProductPost', form, 'POST');
                if (res.success) {
                    this.screenProClick()
                    this.$message({
                        type: 'success',
                        message: '复制成功!'
                    });
                }
            }).catch(() => {
            });
        }
    },
    //监听模版变量
    watch: {}

}
</script>

